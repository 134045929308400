@charset "UTF-8";

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// swiper home carousel styles
// swiper slider main container
.BrandsCarousel {
  width: 100%;

  .brand-carousel-inner {
    max-width: 1280px;
    height: 200px;
    padding: 24px;
    margin: 0 auto;

    .swiper {
      width: 100%;
      height: 100%;

      // swiper additional required wrapper
      .swiper-wrapper {
        // swiper slides
        .swiper-slide {
          width: 100%;
          max-width: 180px;
          height: 100px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          svg:has(use) {
            width: 100%;
            height: auto;
          }
        }
      }

      // pagination
      .swiper-pagination-bullet-active {
        background-color: $global-clr-design-blue;
      }
    }
  }
}
