@charset "UTF-8";

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// main footer styles
.MainFooter {
  width: 100%;
  padding-top: 60px;
  overflow-x: hidden;

  .main-footer-inner {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;

    .main-footer__widgets {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-template-areas:
        'company'
        'site-map'
        'contact';

      @media (min-width: 640px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'company company'
          'site-map contact';
      }

      @media (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: 'company site-map contact';
      }

      .widget {
        width: 320px;
        min-height: 340px;
        padding: 24px;
        justify-self: center;
      }

      .widget__company {
        grid-area: company;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        row-gap: 24px;

        .company__profile-text {
          width: 219px;
          text-align: center;
        }

        .company__social-icons {
          display: flex;
          gap: 36px;
        }
      }

      .widget__site-map {
        grid-area: site-map;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        .site-map__title {
          width: 124px;
          margin-top: 0;
        }

        .site-map__sections-list {
          .section {
            position: relative;
            padding-left: 24px;
            margin-bottom: 12px;

            &:before {
              content: '+';
              width: 6px;
              height: 6px;
              position: absolute;
              top: 0px;
              left: 9px;
            }

            & .section__link {
              color: inherit;

              &:hover {
                color: $global-clr-txt-design-blue;
              }
            }
          }
        }
      }

      .widget__contact {
        grid-area: contact;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        position: relative;

        .contact__title {
          width: 124px;
          margin-top: 0;
          color: inherit;
        }

        .contact__contacts-list {
          .contact__item {
            position: relative;
            padding-left: 56px;
            margin-bottom: 12px;
            z-index: 1;

            &:before {
              width: 28px;
              height: 28px;
              position: absolute;
              top: 10px;
              left: 14px;
            }

            .item__title {
              margin: 0;
              color: inherit;
            }

            .item__link {
              color: inherit;

              &:hover {
                color: $global-clr-txt-design-blue;
              }
            }
          }

          .contact__phone {
            &:before {
              content: url('../../assets/icons/icon-phone-call.svg');
            }
          }

          .contact__email {
            &:before {
              content: url('../../assets/icons/icon-e-mail.svg');
            }
          }

          .contact__location {
            &:before {
              content: url('../../assets/icons/icon-location.svg');
              left: 16px;
            }
          }
        }

        .map-bg {
          width: 600px;
          height: 400px;
          position: absolute;
          top: 0px;
          left: -100px;
          background-image: url('../../assets/images/main-footer-hungary-map-pattern-1400x860.png');
          background-repeat: no-repeat;
          background-size: cover;
          opacity: 0.5;

          @media (max-width: 480px) {
            left: -50px;
          }
        }
      }
    }

    .main-footer__copyright {
      margin: 64px 24px 0;
      padding: 24px 0;
      border-top: 1px solid $global-clr-design-blue;
      text-align: center;

      .developer-name {
        color: $global-clr-txt-design-blue;

        &:hover {
          font-weight: 800;
        }
      }
    }
  }

  .dark-mode {
    color: $global-clr-txt-white;
  }
}
