@charset 'UTF-8';

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// imprint styles
.Imprint {
  width: 100vw;
  height: 100vh;
  background-color: $global-clr-white;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 1000;

  &.dark-mode {
    background-color: $global-clr-graphite;
    color: white;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .imprint-inner {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 24px;
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;

    .imprint__title {
      color: $global-clr-txt-design-blue;
    }

    article {
      width: 100%;
      max-width: 360px;

      h2 {
        font-size: 28px;
        color: $global-clr-txt-design-blue;

        @media (min-width: 480px) {
          font-size: 32px;
        }
      }

      h3 {
        margin: 0;
        color: $global-clr-txt-design-blue;
      }

      ul {
        margin-left: 48px;
      }
    }

    .close-sign {
      position: absolute;
      top: 24px;
      right: 24px;
      cursor: pointer;

      svg:has(use[href='#icon-close']) {
        width: 32px;
        height: 32px;
      }

      svg:has(use[href='#icon-close-dark']) {
        width: 32px;
        height: 32px;
      }
    }
  }
}
