@charset "UTF-8";

// custom properties
@import '../../styles/custom-properties/_variables.scss';

.HomeCarousel {
  width: 100%;
  height: 100%;
  // swiper home carousel styles
  // swiper slider main container
  .swiper {
    width: 100%;
    height: 100%;

    // swiper additional required wrapper
    .swiper-wrapper {
      // swiper slides
      .swiper-slide {
        padding: 48px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        // nested content
        .slide {
          width: 100%;
          max-width: 1280px;
          padding: 16px;
          position: relative;
          z-index: 1;

          svg:has(use[href='#logo-monogram']) {
            height: 36px;
            width: auto;

            @media (min-width: 640px) {
              height: 48px;
            }

            @media (min-width: 1280px) {
              height: 64px;
            }
          }

          .slide__slogan {
            font-size: clamp(16px, 2vw, 20px);
            line-height: clamp(24px, 2vw, 32px);
            text-transform: uppercase;
            text-shadow: $global-clr-graphite 1px 1px 0px;
            color: $global-clr-design-blue;
            margin: 12px 0 6px;
          }

          .slide__title {
            width: 100%;
            max-width: 640px;
            font-size: clamp(24px, 4vw, 50px);
            line-height: clamp(34px, 4vw, 56px);
            color: $global-clr-txt-white;
            text-shadow: $global-clr-graphite 1px 1px 0px;
            margin: 24px 0 24px;
          }

          .slide__contact-container {
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
            align-items: center;
            column-gap: 36px;
            row-gap: 24px;

            .contact__phone-number {
              font-size: 20px;
              color: $global-clr-design-blue;
              vertical-align: middle;
              text-shadow: $global-clr-graphite 1px 1px 0px;

              /* svg:has(use[href='#icon-phone-call']) {
                height: 32px;
                width: auto;
                margin-right: 24px;
                vertical-align: middle;
              } */
            }
          }
        }

        // nested swiper slide bg
        .slide-bg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;

          &.air-conditioner {
            background: url(../../assets/images/home-carousel-air-condition-850x1080.webp);
            background-position: right center;
            background-repeat: no-repeat;
            background-size: cover;

            @media (orientation: landscape) {
              background: url(../../assets/images/home-carousel-air-condition-1440x768.webp);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            @media (min-width: 640px) {
              background: url(../../assets/images/home-carousel-air-condition-1440x768.webp);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            @media (min-width: 1440px) {
              background: url(../../assets/images/home-carousel-air-condition-1920x1080.webp);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }

            @media (min-width: 1920px) {
              background: url(../../assets/images/home-carousel-air-condition-4096x2160.webp);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }

          &.heat-pump {
            background-color: dodgerblue;
            background: url(../../assets/images/home-carousel-heat-pump-1440x768.webp);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }

    // navigation buttons
    .swiper-button-next,
    .swiper-button-prev {
      color: $global-clr-design-blue;

      &:hover {
        color: $global-clr-design-blue-light;
      }
    }

    // pagination
    .swiper-pagination-bullet-active {
      background-color: $global-clr-design-blue;
    }
  }
}
