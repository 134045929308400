@charset "UTF-8";

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// main header styles
.MainHeader {
  width: 100%;
  background-color: $global-clr-trp-white;
  box-shadow: $global-shadow;
  backdrop-filter: blur(8px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  transition: $global-transition;

  &.hide {
    top: -74px;
  }

  &.dark-mode {
    background-color: $global-clr-trp-graphite;
  }

  .main-header-container {
    width: 100%;
    max-width: 1280px;
    height: 74px;
    padding: 16px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .main-header__logo {
      svg:has(use[href='#logo-monogram']) {
        height: 28px;
        width: auto;
      }

      svg:has(use[href='#logo-full-name']) {
        height: 28px;
        width: auto;
      }
    }

    .main-header__dark-mode-toggler {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: $global-transition;

      &.dark-mode:hover {
        background-color: $global-clr-trp-graphite;
      }

      &:hover {
        transform: scale(1.1);
        background-color: hsla(0, 0, 100%, 0.1);
      }
    }
  }
}
