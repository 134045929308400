@charset "UTF-8";
/*License
  Website Name: Lancz Air Conditioning - Lancz Klíma
  Author: András Rajkai
  Author e-mail: rajkaiandras@gmail.com
  Description: lanczklima.hu website CSS Rules
  Version: 2023.06.12.
  License: Commercial*/
/* 
  http://meyerweb.com/eric/tools/css/reset/ 
  v2.0 | 20110126
  License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Raleway-ExtraLight";
  font-style: normal;
  font-weight: 200;
  font-display: fallback;
  src: url(../../assets/fonts/Raleway-ExtraLight.ttf);
}
@font-face {
  font-family: "Raleway-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
  src: url(../../assets/fonts/Raleway-Regular.ttf);
}
@font-face {
  font-family: "Raleway-SemiBold";
  font-style: normal;
  font-weight: 600;
  font-display: fallback;
  src: url(../../assets/fonts/Raleway-SemiBold.ttf);
}
@font-face {
  font-family: "Raleway-ExtraBold";
  font-style: normal;
  font-weight: 800;
  font-display: fallback;
  src: url(../../assets/fonts/Raleway-ExtraBold.ttf);
}
body,
input,
textarea,
button {
  font-family: "Raleway-Regular", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: hsl(0, 0%, 15%);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway-Semi Bold", sans-serif;
  font-style: normal;
  margin: 48px 0 24px;
  color: hsl(0, 0%, 15%);
}

h1 {
  font-size: 50px;
  line-height: 56px;
}

h2 {
  font-size: 32px;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  line-height: 34px;
}

h4 {
  font-size: 20px;
  line-height: 32px;
}

h5 {
  font-size: 18px;
  line-height: 28px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
}

a {
  color: hsl(0, 0%, 15%);
  transition: all 300ms ease-out;
}
a:hover {
  color: hsl(189, 81%, 64%);
}

a {
  color: inherit;
}
a:hover {
  color: hsl(189, 81%, 64%);
}

b,
strong {
  font-weight: 600;
}

.icon-list .icon-list__item {
  padding-left: 18px;
  margin-bottom: 12px;
  position: relative;
}
.icon-list .icon-list__item:before {
  content: url(../../assets/icons/icon-bullet-symbol.svg);
  position: absolute;
  top: 0;
  left: 0;
}

.btn {
  height: 42px;
  padding: 8px 24px;
  color: hsl(0, 0%, 15%);
  display: inline-block;
  transition: all 300ms ease-out;
}
.btn.dark-mode {
  color: hsl(0, 0%, 100%);
}
.btn.dark-mode:hover, .btn.dark-mode:focus {
  color: hsl(189, 81%, 64%);
}
.btn:hover, .btn:focus {
  color: hsl(189, 81%, 64%);
}

.btn--outline {
  border: 1px solid hsl(189, 81%, 64%);
  border-radius: 8px;
  color: hsl(189, 81%, 64%);
}
.btn--outline:hover, .btn--outline:focus {
  color: hsl(0, 0%, 100%);
  background-color: hsl(189, 81%, 64%);
}

.btn--filled {
  border: 1px solid transparent;
  border-radius: 8px;
  color: hsl(0, 0%, 100%);
  background-color: hsl(189, 81%, 64%);
  transition: all 300ms ease-out;
  cursor: pointer;
  /* &:disabled {
    opacity: 0.7;
    cursor: default;

    &:hover {
      color: white;
    }

    &:active {
      transform: scale(1);
    }
  } */
}
.btn--filled:hover, .btn--filled:focus {
  border: 1px solid hsl(189, 81%, 64%);
  color: hsl(189, 81%, 64%);
  background-color: transparent;
}
.btn--filled:active {
  transform: scale(0.975);
}

*,
:before,
:after {
  box-sizing: border-box;
  text-decoration: none;
}

html {
  width: 100vw;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  overflow-x: hidden;
  background-color: hsl(0, 0%, 100%);
}
body.disable-scrolling {
  overflow-y: hidden;
}
body::-webkit-scrollbar {
  display: none;
}
body #root.dark-mode {
  background-color: hsl(0, 0%, 15%);
}/*# sourceMappingURL=shared.css.map */