@charset 'UTF-8';

// custom variables
@import '../../styles/custom-properties/_variables.scss';

// section title styles
.SectionTitle {
  width: 327px;
  height: 92px;
  padding-left: 18px;
  margin-bottom: 60px;
  background-image: url(../../assets/logos/logo-name-grayscale.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:before {
    content: '';
    width: 2px;
    height: 88px;
    border-radius: 1px;
    background-color: $global-clr-design-blue;
    position: absolute;
    top: 2px;
    left: 2px;
  }

  .title,
  .subtitle {
    margin: 0;
  }

  .title {
    line-height: 20px;
    color: $global-clr-design-blue;
  }

  .subtitle {
    font-size: 24px;
    line-height: 24px;
    color: inherit;
  }

  .dark-mode {
    color: $global-clr-txt-white;
  }
}
