// Button Styles
.btn {
  height: 42px;
  padding: 8px 24px;
  color: $global-clr-txt-graphite;
  display: inline-block;
  transition: $global-transition;

  &.dark-mode {
    color: $global-clr-txt-white;

    &:hover,
    &:focus {
      color: $global-clr-txt-design-blue;
    }
  }

  &:hover,
  &:focus {
    color: $global-clr-txt-design-blue;
  }
}

.btn--outline {
  border: 1px solid $global-clr-design-blue;
  border-radius: 8px;
  color: $global-clr-txt-design-blue;

  &:hover,
  &:focus {
    color: $global-clr-txt-white;
    background-color: $global-clr-design-blue;
  }
}

.btn--filled {
  border: 1px solid transparent;
  border-radius: 8px;
  color: $global-clr-txt-white;
  background-color: $global-clr-design-blue;
  transition: $global-transition;
  cursor: pointer;

  &:hover,
  &:focus {
    border: 1px solid $global-clr-design-blue;
    color: $global-clr-txt-design-blue;
    background-color: transparent;
  }

  &:active {
    transform: scale(0.975);
  }

  /* &:disabled {
    opacity: 0.7;
    cursor: default;

    &:hover {
      color: white;
    }

    &:active {
      transform: scale(1);
    }
  } */
}
