@charset "UTF-8";

/*License
  Website Name: Lancz Air Conditioning - Lancz Klíma
  Author: András Rajkai
  Author e-mail: rajkaiandras@gmail.com
  Description: lanczklima.hu website CSS Rules
  Version: 2023.06.12.
  License: Commercial*/

// normalize - CSS reset by Eric Meyer
@import '../normalize/_normalize.scss';

// custom variables
@import '../custom-properties/_variables.scss';

// typography
@import '../typography/_font-faces.scss';
@import '../typography/_typography.scss';

// buttons
@import '../buttons/_buttons.scss';

// general styles
*,
:before,
:after {
  box-sizing: border-box;
  text-decoration: none;
}

html {
  width: 100vw;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  overflow-x: hidden;
  background-color: $global-clr-white;

  // disable scrolling when navigation mobile opened
  &.disable-scrolling {
    overflow-y: hidden;
  }

  // hide vertical scrollbar
  &::-webkit-scrollbar {
    display: none;
  }

  #root {
    &.dark-mode {
      background-color: $global-clr-graphite;
    }
  }
}
