@charset "UTF-8";

// general typography
body,
input,
textarea,
button {
  font-family: 'Raleway-Regular', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: $global-clr-txt-graphite;
}

// headings
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway-Semi Bold', sans-serif;
  font-style: normal;
  margin: 48px 0 24px;
  color: $global-clr-txt-graphite;
}

h1 {
  font-size: 50px;
  line-height: 56px;
}

h2 {
  font-size: 32px;
  line-height: 36px;
}

h3 {
  font-size: 24px;
  line-height: 34px;
}

h4 {
  font-size: 20px;
  line-height: 32px;
}

h5 {
  font-size: 18px;
  line-height: 28px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
}

a {
  color: $global-clr-txt-graphite;
  transition: $global-transition;

  &:hover {
    color: $global-clr-txt-design-blue;
  }
}

a {
  color: inherit;

  &:hover {
    color: $global-clr-txt-design-blue;
  }
}

b,
strong {
  font-weight: 600;
}

// list with icon
.icon-list {
  .icon-list__item {
    padding-left: 18px;
    margin-bottom: 12px;
    position: relative;

    &:before {
      content: url(../../assets/icons/icon-bullet-symbol.svg);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
