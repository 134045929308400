.HomeCarousel {
  width: 100%;
  height: 100%;
}
.HomeCarousel .swiper {
  width: 100%;
  height: 100%;
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide {
  padding: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide {
  width: 100%;
  max-width: 1280px;
  padding: 16px;
  position: relative;
  z-index: 1;
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide svg:has(use[href="#logo-monogram"]) {
  height: 36px;
  width: auto;
}
@media (min-width: 640px) {
  .HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide svg:has(use[href="#logo-monogram"]) {
    height: 48px;
  }
}
@media (min-width: 1280px) {
  .HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide svg:has(use[href="#logo-monogram"]) {
    height: 64px;
  }
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide .slide__slogan {
  font-size: clamp(16px, 2vw, 20px);
  line-height: clamp(24px, 2vw, 32px);
  text-transform: uppercase;
  text-shadow: hsl(0, 0%, 15%) 1px 1px 0px;
  color: hsl(189, 81%, 64%);
  margin: 12px 0 6px;
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide .slide__title {
  width: 100%;
  max-width: 640px;
  font-size: clamp(24px, 4vw, 50px);
  line-height: clamp(34px, 4vw, 56px);
  color: hsl(0, 0%, 100%);
  text-shadow: hsl(0, 0%, 15%) 1px 1px 0px;
  margin: 24px 0 24px;
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide .slide__contact-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  -moz-column-gap: 36px;
       column-gap: 36px;
  row-gap: 24px;
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide .slide__contact-container .contact__phone-number {
  font-size: 20px;
  color: hsl(189, 81%, 64%);
  vertical-align: middle;
  text-shadow: hsl(0, 0%, 15%) 1px 1px 0px;
  /* svg:has(use[href='#icon-phone-call']) {
    height: 32px;
    width: auto;
    margin-right: 24px;
    vertical-align: middle;
  } */
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide-bg.air-conditioner {
  background: url(../../assets/images/home-carousel-air-condition-850x1080.webp);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (orientation: landscape) {
  .HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide-bg.air-conditioner {
    background: url(../../assets/images/home-carousel-air-condition-1440x768.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media (min-width: 640px) {
  .HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide-bg.air-conditioner {
    background: url(../../assets/images/home-carousel-air-condition-1440x768.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media (min-width: 1440px) {
  .HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide-bg.air-conditioner {
    background: url(../../assets/images/home-carousel-air-condition-1920x1080.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
@media (min-width: 1920px) {
  .HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide-bg.air-conditioner {
    background: url(../../assets/images/home-carousel-air-condition-4096x2160.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.HomeCarousel .swiper .swiper-wrapper .swiper-slide .slide-bg.heat-pump {
  background-color: dodgerblue;
  background: url(../../assets/images/home-carousel-heat-pump-1440x768.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.HomeCarousel .swiper .swiper-button-next,
.HomeCarousel .swiper .swiper-button-prev {
  color: hsl(189, 81%, 64%);
}
.HomeCarousel .swiper .swiper-button-next:hover,
.HomeCarousel .swiper .swiper-button-prev:hover {
  color: hsl(189, 81%, 90%);
}
.HomeCarousel .swiper .swiper-pagination-bullet-active {
  background-color: hsl(189, 81%, 64%);
}/*# sourceMappingURL=HomeCarousel.css.map */