@charset 'UTF-8';

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// price offer form styles
.PriceOfferForm {
  width: 100%;
  max-width: 640px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 16px;
  grid-template-areas:
    'name'
    'phone'
    'email'
    'subject'
    'info'
    'submit';
  position: relative;
  grid-area: form;
  justify-self: center;

  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 16px;
    grid-template-areas:
      'name name phone phone'
      'email email subject subject'
      'info info info info'
      'submit submit . .';
  }

  @media (min-width: 1024px) {
    align-self: end;
  }

  .input--full-name-wrapper {
    grid-area: name;
    position: relative;

    svg:has(use[href='#icon-person-fill']) {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 22px;
      right: 22px;
    }
  }

  .input--phone-number-wrapper {
    grid-area: phone;

    position: relative;

    svg:has(use[href='#icon-phone-fill']) {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 22px;
      right: 22px;
    }
  }

  .input--e-mail-wrapper {
    grid-area: email;
    position: relative;

    svg:has(use[href='#icon-mail-fill']) {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 22px;
      right: 22px;
    }
  }

  .input--offer-subject-wrapper {
    grid-area: subject;
    position: relative;

    svg:has(use[href='#icon-engineering-fill']) {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .input--offer-information-wrapper {
    grid-area: info;
    position: relative;

    svg:has(use[href='#icon-info-fill']) {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }

  .input--submit-wrapper {
    grid-area: submit;
    position: relative;

    .btn--submit {
      width: 100%;
    }
  }

  input,
  select {
    width: 100%;
    height: 60px;
    padding: 16px 16px 16px 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    box-shadow: $global-shadow;

    &:focus {
      border: 2px solid $global-clr-design-blue;
    }
  }

  textarea {
    width: 100%;
    height: 180px;
    padding: 16px 56px 16px 16px;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    box-shadow: $global-shadow;

    &:focus {
      border: 2px solid $global-clr-design-blue;
    }
  }

  textarea {
    resize: none;
  }

  .frame-decoration {
    display: none;
    width: calc(100% + 260px);
    height: calc(100% + 34px);
    border: 2px solid $global-clr-design-blue;
    border-radius: 8px;
    background: transparent;
    position: absolute;
    top: -16px;
    left: -16px;
    z-index: -1;

    @media (min-width: 1024px) {
      display: block;
    }
  }
}
