@charset 'UTF-8';

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// price offer feedback modal styles
.PriceOfferFeedbackModal {
  width: 260px;
  height: auto;
  padding: 16px;
  border: 2px solid $global-clr-design-blue;
  border-radius: 8px;
  background-color: $global-clr-white;
  position: fixed;
  top: calc(50vh - 120px);
  left: calc(50vw - 130px);
  z-index: 999;

  &.dark-mode {
    background-color: $global-clr-graphite;

    .modal__subtitle {
      color: $global-clr-txt-white;
    }
  }

  svg:has(use[href='#logo-monogram']) {
    height: 36px;
    width: auto;
    position: absolute;
    top: -37px;
    left: calc(130px - 20px);
  }

  .modal__title {
    color: $global-clr-txt-design-blue;
    margin: 0 0 16px;
    text-align: center;
  }

  .modal__subtitle {
    color: $global-clr-txt-graphite;
    text-align: center;
  }
}
