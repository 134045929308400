.SectionTitle {
  width: 327px;
  height: 92px;
  padding-left: 18px;
  margin-bottom: 60px;
  background-image: url(../../assets/logos/logo-name-grayscale.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.SectionTitle:before {
  content: "";
  width: 2px;
  height: 88px;
  border-radius: 1px;
  background-color: hsl(189, 81%, 64%);
  position: absolute;
  top: 2px;
  left: 2px;
}
.SectionTitle .title,
.SectionTitle .subtitle {
  margin: 0;
}
.SectionTitle .title {
  line-height: 20px;
  color: hsl(189, 81%, 64%);
}
.SectionTitle .subtitle {
  font-size: 24px;
  line-height: 24px;
  color: inherit;
}
.SectionTitle .dark-mode {
  color: hsl(0, 0%, 100%);
}/*# sourceMappingURL=SectionTitle.css.map */