// custom Properties
@import '../../styles/custom-properties/_variables.scss';

// scroll top styles
.ScrollTop {
  position: fixed;
  bottom: 30px;
  left: 85vw;
  z-index: 3;
  cursor: pointer;

  .scroll-top-wrapper {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: $global-clr-design-blue;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: $global-transition;

    svg:has(use[href='#icon-top-arrow']) {
      width: 36px;
      height: 36px;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
}
