.Imprint {
  width: 100vw;
  height: 100vh;
  background-color: hsl(0, 0%, 100%);
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 1000;
}
.Imprint.dark-mode {
  background-color: hsl(0, 0%, 15%);
  color: white;
}
.Imprint::-webkit-scrollbar {
  display: none;
}
.Imprint .imprint-inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px;
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
}
.Imprint .imprint-inner .imprint__title {
  color: hsl(189, 81%, 64%);
}
.Imprint .imprint-inner article {
  width: 100%;
  max-width: 360px;
}
.Imprint .imprint-inner article h2 {
  font-size: 28px;
  color: hsl(189, 81%, 64%);
}
@media (min-width: 480px) {
  .Imprint .imprint-inner article h2 {
    font-size: 32px;
  }
}
.Imprint .imprint-inner article h3 {
  margin: 0;
  color: hsl(189, 81%, 64%);
}
.Imprint .imprint-inner article ul {
  margin-left: 48px;
}
.Imprint .imprint-inner .close-sign {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.Imprint .imprint-inner .close-sign svg:has(use[href="#icon-close"]) {
  width: 32px;
  height: 32px;
}
.Imprint .imprint-inner .close-sign svg:has(use[href="#icon-close-dark"]) {
  width: 32px;
  height: 32px;
}/*# sourceMappingURL=Imprint.css.map */