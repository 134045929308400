// colors
$global-clr-white: hsl(0, 0%, 100);
$global-clr-graphite: hsl(0, 0%, 15);
$global-clr-design-blue: hsl(189, 81%, 64);
$global-clr-design-blue-light: hsl(189, 81%, 90);

// transparent colors
$global-clr-trp-white: hsla(0, 0, 100%, 0.4);
$global-clr-trp-graphite: hsla(0, 0, 0%, 0.4);

// gradient colors
$global-clr-gradient: linear-gradient(
  to right,
  hsl(13, 100, 78),
  hsl(338, 98, 62)
);

// text colors
$global-clr-txt-white: hsl(0, 0%, 100);
$global-clr-txt-graphite: hsl(0, 0%, 15);
$global-clr-txt-design-blue: hsl(189, 81%, 64);

// shadows
$global-shadow: 0 5px 16px hsla(0, 0%, 0, 0.1);

// transitions
$global-transition: all 300ms ease-out;
