.PriceOfferForm {
  width: 100%;
  max-width: 640px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 16px;
  grid-template-areas: "name" "phone" "email" "subject" "info" "submit";
  position: relative;
  grid-area: form;
  justify-self: center;
}
@media (min-width: 640px) {
  .PriceOfferForm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    -moz-column-gap: 16px;
         column-gap: 16px;
    grid-template-areas: "name name phone phone" "email email subject subject" "info info info info" "submit submit . .";
  }
}
@media (min-width: 1024px) {
  .PriceOfferForm {
    align-self: end;
  }
}
.PriceOfferForm .input--full-name-wrapper {
  grid-area: name;
  position: relative;
}
.PriceOfferForm .input--full-name-wrapper svg:has(use[href="#icon-person-fill"]) {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 22px;
  right: 22px;
}
.PriceOfferForm .input--phone-number-wrapper {
  grid-area: phone;
  position: relative;
}
.PriceOfferForm .input--phone-number-wrapper svg:has(use[href="#icon-phone-fill"]) {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 22px;
  right: 22px;
}
.PriceOfferForm .input--e-mail-wrapper {
  grid-area: email;
  position: relative;
}
.PriceOfferForm .input--e-mail-wrapper svg:has(use[href="#icon-mail-fill"]) {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 22px;
  right: 22px;
}
.PriceOfferForm .input--offer-subject-wrapper {
  grid-area: subject;
  position: relative;
}
.PriceOfferForm .input--offer-subject-wrapper svg:has(use[href="#icon-engineering-fill"]) {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.PriceOfferForm .input--offer-information-wrapper {
  grid-area: info;
  position: relative;
}
.PriceOfferForm .input--offer-information-wrapper svg:has(use[href="#icon-info-fill"]) {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  right: 20px;
}
.PriceOfferForm .input--submit-wrapper {
  grid-area: submit;
  position: relative;
}
.PriceOfferForm .input--submit-wrapper .btn--submit {
  width: 100%;
}
.PriceOfferForm input,
.PriceOfferForm select {
  width: 100%;
  height: 60px;
  padding: 16px 16px 16px 16px;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 5px 16px hsla(0, 0%, 0%, 0.1);
}
.PriceOfferForm input:focus,
.PriceOfferForm select:focus {
  border: 2px solid hsl(189, 81%, 64%);
}
.PriceOfferForm textarea {
  width: 100%;
  height: 180px;
  padding: 16px 56px 16px 16px;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 5px 16px hsla(0, 0%, 0%, 0.1);
}
.PriceOfferForm textarea:focus {
  border: 2px solid hsl(189, 81%, 64%);
}
.PriceOfferForm textarea {
  resize: none;
}
.PriceOfferForm .frame-decoration {
  display: none;
  width: calc(100% + 260px);
  height: calc(100% + 34px);
  border: 2px solid hsl(189, 81%, 64%);
  border-radius: 8px;
  background: transparent;
  position: absolute;
  top: -16px;
  left: -16px;
  z-index: -1;
}
@media (min-width: 1024px) {
  .PriceOfferForm .frame-decoration {
    display: block;
  }
}/*# sourceMappingURL=PriceOfferForm.css.map */