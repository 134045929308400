.BrandsCarousel {
  width: 100%;
}
.BrandsCarousel .brand-carousel-inner {
  max-width: 1280px;
  height: 200px;
  padding: 24px;
  margin: 0 auto;
}
.BrandsCarousel .brand-carousel-inner .swiper {
  width: 100%;
  height: 100%;
}
.BrandsCarousel .brand-carousel-inner .swiper .swiper-wrapper .swiper-slide {
  width: 100%;
  max-width: 180px;
  height: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BrandsCarousel .brand-carousel-inner .swiper .swiper-wrapper .swiper-slide svg:has(use) {
  width: 100%;
  height: auto;
}
.BrandsCarousel .brand-carousel-inner .swiper .swiper-pagination-bullet-active {
  background-color: hsl(189, 81%, 64%);
}/*# sourceMappingURL=BrandsCarousel.css.map */