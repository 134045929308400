.PriceOfferInfoModal {
  width: 100%;
  max-width: 320px;
  padding: 8px;
  border: 2px solid hsl(189, 81%, 64%);
  border-radius: 8px;
  font-size: 14px;
  background-color: hsl(0, 0%, 100%);
  position: absolute;
  top: -198px;
  right: 0;
}
@media (min-width: 1024px) {
  .PriceOfferInfoModal {
    top: 0;
    right: -324px;
    z-index: 3;
  }
}
.PriceOfferInfoModal .price-offer-info-modal__title {
  margin: 0;
  font-size: inherit;
  color: hsl(0, 0%, 15%);
}
.PriceOfferInfoModal .price-offer-info-modal__title.dark-mode {
  color: hsl(189, 81%, 64%);
}
.PriceOfferInfoModal .price-offer-info-modal__list {
  padding-left: 24px;
  list-style-type: disc;
}
.PriceOfferInfoModal svg:has(use[href="#icon-modal-arrow"]) {
  transform: rotate(-90deg);
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: -10px;
  right: 21px;
}
@media (min-width: 1024px) {
  .PriceOfferInfoModal svg:has(use[href="#icon-modal-arrow"]) {
    transform: rotate(0);
    top: 20px;
    left: -10px;
    z-index: 3;
  }
}
.PriceOfferInfoModal.dark-mode {
  background-color: hsl(0, 0%, 15%);
}/*# sourceMappingURL=PriceOfferInfoModal.css.map */