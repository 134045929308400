.NavigationMobile {
  width: 100vw;
  height: 100vh;
  background-color: hsl(0, 0%, 100%);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 5;
  transition: all 300ms ease-out;
  transition-delay: 100ms;
}
.NavigationMobile.dark-mode {
  background-color: hsl(0, 0%, 15%);
}
.NavigationMobile.open {
  transform: translateX(100vw);
}
.NavigationMobile .navigation-mobile__list {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  row-gap: 24px;
  justify-content: center;
  align-items: center;
  position: relative;
}
.NavigationMobile .navigation-mobile__list .navigation-mobile__item svg:has(use[href="#logo-monogram"]) {
  width: auto;
  height: 48px;
  position: relative;
  top: -24px;
}
.NavigationMobile .navigation-mobile__list .navigation-mobile__item .btn {
  color: hsl(0, 0%, 15%);
}
.NavigationMobile .navigation-mobile__list .navigation-mobile__item .btn.dark-mode {
  color: hsl(0, 0%, 100%);
}
.NavigationMobile .navigation-mobile__list .navigation-mobile__item .btn:hover, .NavigationMobile .navigation-mobile__list .navigation-mobile__item .btn:focus {
  color: hsl(189, 81%, 64%);
}
.NavigationMobile .navigation-mobile__list .navigation-mobile__item .btn--outline {
  color: hsl(189, 81%, 64%);
}
.NavigationMobile .navigation-mobile__list .navigation-mobile__item .btn--outline:hover, .NavigationMobile .navigation-mobile__list .navigation-mobile__item .btn--outline:focus {
  color: hsl(0, 0%, 100%);
  background-color: hsl(189, 81%, 64%);
}/*# sourceMappingURL=NavigationMobile.css.map */