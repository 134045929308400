.ScrollTop {
  position: fixed;
  bottom: 30px;
  left: 85vw;
  z-index: 3;
  cursor: pointer;
}
.ScrollTop .scroll-top-wrapper {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: hsl(189, 81%, 64%);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 300ms ease-out;
}
.ScrollTop .scroll-top-wrapper svg:has(use[href="#icon-top-arrow"]) {
  width: 36px;
  height: 36px;
}
.ScrollTop .scroll-top-wrapper:hover {
  transform: scale(1.1);
}/*# sourceMappingURL=ScrollTop.css.map */