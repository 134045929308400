.PriceOffer {
  width: 100%;
  padding-top: 60px;
  background-image: url(../../assets/images/price-offer-wall-of-acs-1440x960.webp);
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1024px) {
  .PriceOffer {
    background: none;
  }
}
.PriceOffer .price-offer-inner {
  width: 100%;
  max-width: 1280px;
  padding: 24px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 24px;
  grid-template-areas: "title" "form" "image";
}
@media (min-width: 1024px) {
  .PriceOffer .price-offer-inner {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 92px 1fr;
    -moz-column-gap: 24px;
         column-gap: 24px;
    grid-template-areas: "title image" "form image";
  }
}
.PriceOffer .price-offer-inner .section-title-wrapper {
  width: 100%;
  max-width: 640px;
  margin: 0 auto;
  grid-area: title;
}
@media (min-width: 1024px) {
  .PriceOffer .price-offer-inner .section-title-wrapper {
    max-width: 1280px;
  }
}
.PriceOffer .price-offer-inner .section-title-wrapper .SectionTitle {
  margin-bottom: 36px;
}
.PriceOffer .price-offer-inner .bg-image {
  display: none;
  grid-area: image;
  width: 100%;
  height: auto;
  max-height: 540px;
  aspect-ratio: 1/1;
  background-image: url(../../assets/images/price-offer-wall-of-acs-640x600.webp);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 2;
}
@media (min-width: 1024px) {
  .PriceOffer .price-offer-inner .bg-image {
    display: block;
  }
}

.dark-mode {
  color: hsl(0, 0%, 100%);
}/*# sourceMappingURL=PriceOffer.css.map */