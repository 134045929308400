@charset "UTF-8";

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// navigation mobile styles
.NavigationMobile {
  width: 100vw;
  height: 100vh;
  background-color: $global-clr-white;
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: -100vw;
  z-index: 5;
  transition: $global-transition;
  transition-delay: 100ms;

  &.dark-mode {
    background-color: $global-clr-graphite;
  }

  &.open {
    transform: translateX(100vw);
  }

  .navigation-mobile__list {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    row-gap: 24px;
    justify-content: center;
    align-items: center;
    position: relative;

    .navigation-mobile__item {
      svg:has(use[href='#logo-monogram']) {
        width: auto;
        height: 48px;
        position: relative;
        top: -24px;
      }

      .btn {
        color: $global-clr-txt-graphite;

        &.dark-mode {
          color: $global-clr-txt-white;
        }

        &:hover,
        &:focus {
          color: $global-clr-txt-design-blue;
        }
      }

      .btn--outline {
        color: $global-clr-design-blue;

        &:hover,
        &:focus {
          color: $global-clr-txt-white;
          background-color: $global-clr-design-blue;
        }
      }
    }
  }
}
