@charset "UTF-8";

// backdrop styles
.Backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  backdrop-filter: blur(4px);
  top: 0;
  left: 0;
  z-index: 998;
}
