@charset "UTF-8";

// custom properties
@import '../../styles/custom-properties/_variables.scss';

// price offer info modal styles
.PriceOfferInfoModal {
  width: 100%;
  max-width: 320px;
  padding: 8px;
  border: 2px solid $global-clr-design-blue;
  border-radius: 8px;
  font-size: 14px;
  background-color: $global-clr-white;
  position: absolute;
  top: -198px;
  right: 0;

  @media (min-width: 1024px) {
    top: 0;
    right: -324px;
    z-index: 3;
  }

  .price-offer-info-modal__title {
    margin: 0;
    font-size: inherit;
    color: $global-clr-txt-graphite;

    &.dark-mode {
      color: $global-clr-txt-design-blue;
    }
  }

  .price-offer-info-modal__list {
    padding-left: 24px;
    list-style-type: disc;
  }

  svg:has(use[href='#icon-modal-arrow']) {
    transform: rotate(-90deg);
    width: 16px;
    height: 16px;
    position: absolute;
    bottom: -10px;
    right: 21px;

    @media (min-width: 1024px) {
      transform: rotate(0);
      top: 20px;
      left: -10px;
      z-index: 3;
    }
  }

  &.dark-mode {
    background-color: $global-clr-graphite;
  }
}
