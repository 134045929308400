.HamburgerMenu {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: relative;
  z-index: 6;
  cursor: pointer;
}
.HamburgerMenu .hamburger-menu__bar {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 1px);
  width: 24px;
  height: 1.5px;
  border-radius: 4px;
  background-color: hsl(0, 0%, 15%);
  transition: all 300ms ease-out;
}
.HamburgerMenu .hamburger-menu__bar.open {
  background-color: hsl(0, 0%, 15%);
}
.HamburgerMenu .hamburger-menu__bar.dark-mode {
  background-color: hsl(0, 0%, 100%);
}
.HamburgerMenu .hamburger-menu__bar.dark-mode.open {
  background-color: hsl(0, 0%, 100%);
}
.HamburgerMenu .bar-top {
  transform: translateY(-6px);
}
.HamburgerMenu .bar-top.open {
  transform: rotate(40deg);
}
.HamburgerMenu .bar-center.open {
  opacity: 0;
}
.HamburgerMenu .bar-bottom {
  transform: translateY(6px);
}
.HamburgerMenu .bar-bottom.open {
  transform: rotate(-40deg);
}/*# sourceMappingURL=HamburgerMenu.css.map */